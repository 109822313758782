import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/Layout"

const IndexPage = () => (
    <Layout
        headerTitle="Welcome to Spacehead"
        headerSubtitle="CitizenCon 2949 Microsite"
    >
        <h2>Header Two H2</h2>
        <p>
            Diam donec adipiscing tristique risus nec feugiat in fermentum
            posuere. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque
            viverra. Netus et malesuada fames ac turpis egestas. Nec tincidunt
            praesent semper feugiat nibh sed pulvinar proin gravida. Elementum
            curabitur vitae nunc sed velit dignissim sodales. Nam libero justo
            laoreet sit amet cursus sit amet dictum. Sapien pellentesque
            habitant morbi tristique senectus et netus et malesuada. Ut tellus
            elementum sagittis vitae et leo duis ut diam. Massa tincidunt nunc
            pulvinar sapien et ligula ullamcorper malesuada proin. Enim
            facilisis gravida neque convallis a. In eu mi bibendum neque egestas
            congue quisque.
        </p>
        <h3>Header Three H3</h3>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Libero
            enim sed faucibus turpis. Vel quam elementum pulvinar etiam. Leo
            duis ut diam quam nulla porttitor massa. Nulla aliquet porttitor
            lacus luctus accumsan tortor posuere ac ut. Pellentesque id nibh
            tortor id aliquet lectus proin nibh. Sociis natoque penatibus et
            magnis dis parturient. Faucibus ornare suspendisse sed nisi lacus.
            At in tellus integer feugiat. Donec massa sapien faucibus et
            molestie ac feugiat. Odio pellentesque diam volutpat commodo sed
            egestas egestas fringilla phasellus. Nam aliquam sem et tortor
            consequat id porta nibh. Magna eget est lorem ipsum dolor sit. Neque
            volutpat ac tincidunt vitae semper quis lectus. Velit scelerisque in
            dictum non consectetur a erat nam. Pretium viverra suspendisse
            potenti nullam ac tortor vitae purus faucibus.
        </p>
        <h4>Header Four H4</h4>
        <p>
            Diam donec adipiscing tristique risus nec feugiat in fermentum
            posuere. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque
            viverra. Netus et malesuada fames ac turpis egestas. Nec tincidunt
            praesent semper feugiat nibh sed pulvinar proin gravida. Elementum
            curabitur vitae nunc sed velit dignissim sodales. Nam libero justo
            laoreet sit amet cursus sit amet dictum. Sapien pellentesque
            habitant morbi tristique senectus et netus et malesuada. Ut tellus
            elementum sagittis vitae et leo duis ut diam. Massa tincidunt nunc
            pulvinar sapien et ligula ullamcorper malesuada proin. Enim
            facilisis gravida neque convallis a. In eu mi bibendum neque egestas
            congue quisque.
        </p>
        <h5>Header Five H5</h5>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Libero
            enim sed faucibus turpis. Vel quam elementum pulvinar etiam. Leo
            duis ut diam quam nulla porttitor massa. Nulla aliquet porttitor
            lacus luctus accumsan tortor posuere ac ut. Pellentesque id nibh
            tortor id aliquet lectus proin nibh. Sociis natoque penatibus et
            magnis dis parturient. Faucibus ornare suspendisse sed nisi lacus.
            At in tellus integer feugiat. Donec massa sapien faucibus et
            molestie ac feugiat. Odio pellentesque diam volutpat commodo sed
            egestas egestas fringilla phasellus. Nam aliquam sem et tortor
            consequat id porta nibh. Magna eget est lorem ipsum dolor sit. Neque
            volutpat ac tincidunt vitae semper quis lectus. Velit scelerisque in
            dictum non consectetur a erat nam. Pretium viverra suspendisse
            potenti nullam ac tortor vitae purus faucibus.
        </p>
        <h6>Header Six H6</h6>
        <p>
            Diam donec adipiscing tristique risus nec feugiat in fermentum
            posuere. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque
            viverra. Netus et malesuada fames ac turpis egestas. Nec tincidunt
            praesent semper feugiat nibh sed pulvinar proin gravida. Elementum
            curabitur vitae nunc sed velit dignissim sodales. Nam libero justo
            laoreet sit amet cursus sit amet dictum. Sapien pellentesque
            habitant morbi tristique senectus et netus et malesuada. Ut tellus
            elementum sagittis vitae et leo duis ut diam. Massa tincidunt nunc
            pulvinar sapien et ligula ullamcorper malesuada proin. Enim
            facilisis gravida neque convallis a. In eu mi bibendum neque egestas
            congue quisque.
        </p>
        <h6 className="small">Small H6</h6>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Libero
            enim sed faucibus turpis. Vel quam elementum pulvinar etiam. Leo
            duis ut diam quam nulla porttitor massa. Nulla aliquet porttitor
            lacus luctus accumsan tortor posuere ac ut. Pellentesque id nibh
            tortor id aliquet lectus proin nibh. Sociis natoque penatibus et
            magnis dis parturient. Faucibus ornare suspendisse sed nisi lacus.
            At in tellus integer feugiat. Donec massa sapien faucibus et
            molestie ac feugiat. Odio pellentesque diam volutpat commodo sed
            egestas egestas fringilla phasellus. Nam aliquam sem et tortor
            consequat id porta nibh. Magna eget est lorem ipsum dolor sit. Neque
            volutpat ac tincidunt vitae semper quis lectus. Velit scelerisque in
            dictum non consectetur a erat nam. Pretium viverra suspendisse
            potenti nullam ac tortor vitae purus faucibus.
        </p>
    </Layout>
)

export default IndexPage
